body #root {
    font-family: 'Jost';
}

@media only screen and (max-width: 768px) {
    .ui.container {
        margin-left: 0em !important;
        margin-right: 0em !important;
    }
}

.ui.menu {
    border: 0px;
    box-shadow: 0 0px 0px 0 rgba(34,36,38,.15);
    border-radius: 0;
    margin: 0;
}

.ui.primary.button {
    background-color: #1876d2;
}

.underline {
    box-shadow: 0 3px 0px 0 rgba(0,0,0,.05);
}

.mainbackground2 {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.mainbackground3 {
    background-color: #353535;
    padding-top: 90px;
    padding-bottom: 80px;
}

h1.ui.header {
    font-size: 3.2rem
}

.ui.header {
    font-family: 'Jost';
    font-weight: 700;
}

.ui.menu .item {
    padding: .92857143em 0;
}

.fontsize1 {
    font-size: 1.2em;
}

.ui.image {
    margin-left: auto;
    margin-right: auto;
}

.sidebarmenu {
    font-size: 1.2em;
    text-align: right;
    margin: 1.3em;
}

.refimage {
    padding-left: 15px;
    padding-right: 15px;
}

.GridPadding {
    padding: 30px;
}

.portfolioBlock {
    padding: 10%;

}
